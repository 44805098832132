import React, { Component } from 'react';

class Hero extends Component {
    render() {
        return (
            <div className='hero-container'>
                <h1 className='heroText'>Stuart Sim</h1>
            </div>
        );
    }
}

export default Hero;