import React from 'react';


export default function Connect() {
    return (
        <a 
        className='workitem_container workitem_container-olympics workitem_container-olympics-half'
        href="https://twitter.com/StuSim"
        target={'_blank'}
        rel='noopener noreferrer'>
            <p>Contact</p>
        </a>

    );
}